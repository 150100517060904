<template>
  <div class="resources-container">
    <div class="resources-content clear" v-loading="pageLoading">
      <div class="tab-secondNav" v-if="secondNav.length > 0" style="margin-bottom:0.3rem">
        <ul class="tab-secondNav-choose-item">
          <li
            v-for="(item, i) in secondNav"
            :key="i"
            :class="{ tabActivesecondNav: cur2 == item.id }"
            @click="changeSecondNav(item, i)"
          >
            {{ item.name }}
            <span :class="{ tabActivesecondNavSpan: cur2 == item.id }">
              <span
                :class="{ tabActivesecondNavSubSpan: cur2 == item.id }"
              ></span>
            </span>
          </li>
        </ul>
      </div>
      <div class="resources-switch-type" v-if="categoryList.length > 0">
        <div class="resources-switch-type-title"></div>
        <div class="resources-switch-type-content">
          <div
            class="resources-switch-type-content-li"
            :class="{
              'resources-switch-type-content-li-active': cur == item.id,
              imgType: item.label_name.length > 4,
            }"
            v-for="(item, index) in categoryList"
            :key="index"
            @click="handleClickCategory(item)"
          >
            {{ item.label_name }}
          </div>
        </div>
      </div>

      <!-- 最新·最热 -->
      <!-- <div class="resources-content-hot-new">
        <div class="resources-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="resources-content-hot-new-title"
          @click="handleChangeValue2('sort', 0)"
        >
          <div
            :style="{
              'font-weight': value == 'sort' ? 'bold' : '',
            }"
          >
            默认
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{
              display: value == 'sort' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="resources-content-new-mid-hot"></div>
        <div
          class="resources-content-hot-new-title"
          @click="handleChangeValue2('id', 1)"
        >
          <div
            :style="{
              'font-weight': value == 'id' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{
              display: value == 'id' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="resources-content-new-mid-hot"></div>
        <div
          class="resources-content-hot-new-title resources-content-hot-new-title1"
          @click="handleChangeValue2('view_count', 2)"
        >
          <div :style="{ 'font-weight': value == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{ display: value == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="resources-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div> -->

      <!-- 类型的tab切换中的假内容 -->
      <div class="resources-tab-content clear">
        <div>
          <ul class="resources-ul">
            <li
              class="resources-ul-li"
              v-for="(item, index) in content"
              :key="'resource_e' + index"
            >
              <div @click="toNewPage(item)">
                <div class="resources-ul-li-banner">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.main_pic"
                    alt=""
                  />
                </div>
                <div class="resources-ul-li-bottom">
                  <div class="resources-ul-li-bottom-title">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 电子资源库的类型为文学小说时的布局 1=视频，2=音频，3=电子书-->
        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="bookLimit"
          @pagination="getResourceList"
        />
        <!-- 没有内容显示的页面 -->
        <Nocontent
          v-if="content.length == 0"
          title="暂无数据，请持续关注哦～"
        ></Nocontent>
      </div>
    </div>
  </div>
</template>

<script>
import { ResourceList } from "@/api/common";
import Pagination from "@/components/Pagination";
import Nocontent from "@/views/components/Nocontent";
import { navChildList } from "@/api/header/header";

export default {
  data() {
    return {
      pageLoading: false,
      listLoading: true,
      total: 0,
      page: 1,
      limit: 6,
      bookLimit: 6,
      curr: -1,
      current: -1,
      order: "sort",
      currentType: 0,
      typeList: [],
      subTypeList: [],
      aid: process.env.VUE_APP_AID,
      elcTypeList: [],
      content: [],
      subCategory: "",
      cat_id: "",
      active_tab: 0,
      value: "sort",
      isSelected: true,
      firstId: "",
      firstCatId: "",
      getChild: 1,
      class_id: this.$route.query.id,

      pc_template_status: 1, //1为图文 2为列表
      secondNav: [],
      cur2: 0,
      categoryList: [],
      cur: 0,
    };
  },
  components: {
    Pagination,
    Nocontent,
  },
  props: {
    nav: {
      type: Array,
      default: null,
    },
  },
  watch: {
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getResourceList();
      this.getChildNav();
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.column_id = this.$route.query.column_id;
    this.cid = this.$route.query.cid;
    if (this.cid) {
      this.cur2 = this.cid;
    }
    this.getChildNav();
    this.getResourceList();
  },
  methods: {
    getQueryVariable(url, variable) {
      var query = url.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
      // ['https://qq.com','appid=1234567890&name=Zdf']
      var vars = query.split("&"); // 根据“&”切割成数组
      // ['appid=1234567890','name=Zdf']
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // ['appid','1234567890']
        if (pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
      }
      return false;
    },
    changeSecondNav(item) {
      console.log(item)
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
            `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
          );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
            `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
          );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };
      navChildList(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.secondNav = res.data;
        }
      });
    },
    handleClickCategory(item) {
      this.page = 1;
      this.cur = item.id;
      this.getResourceList();
    },
    handleClickSubCategory(i, item) {
      this.curr = -1;
      this.page = 1;
      this.active_tab = 0;
      this.current = i;
      this.cat_id = item.children_data ? item.children_data[0].id : item.id;
      this.subTypeList = item.children_data ? item.children_data : [];
      this.page = 1;
      // this.getResourceList();
    },
    getResourceList() {
      let params = {
        aid: this.aid,
        page: this.page,
        limit: this.limit,
        column_id: this.column_id,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = parseInt(this.cur);
      }
      // if (this.value != "") {
      //   params.sort_field = this.value;
      // }
      ResourceList(params).then((res) => {
        if (res.code == 0) {
          this.content = res.data.datalist;
          this.total = res.data.count;
          if (res.data.label_list.length > 0) {
            this.categoryList = res.data.label_list;
            this.categoryList.unshift({
              id: 0,
              label_name: "全部",
            });
          }
        }
      });
    },
    handleChangeValue2(value, i) {
      this.value = value;
      this.page = 1;
      this.getResourceList();
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");
      } else {
        let routeUrl = this.$router.resolve({
          path: `/resource-default/detail`,
          query: {
            tid: item.id,
            id: this.$route.query.id,
            cid: this.$route.query.cid,
            column_id: this.$route.query.column_id,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/resource_default_index.scss";
@import "../../assets/scss/header_common.scss";
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item {
  display: flex;
}
.tab-secondNav-choose-item li {
  margin-left: 10px;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.38rem;
}
.resources-ul-li-bottom-title {
  width: 100%;
  height: 0.76rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #273143;
  line-height: 0.36rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0.11rem;
}
li {
  overflow: visible;
}
</style>
